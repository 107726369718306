<template>
  <div class="navbar-navigation pr-3">
    <MenuList
      v-for="option in options"
      :key="option.title"
      :item="option"
    ></MenuList>
    <Notification />
  </div>
</template>
<script>
import MenuList from "@/components/MenuList.vue";
import Notification from "@/components/Notification.vue";
export default {
  components: { MenuList, Notification },
  data() {
    return {
      options: [
        {
          title: "company",
          icon: "work",
          store: "Company",
        },
        {
          title: "site",
          icon: "business",
          store: "Site",
          viewAll: "/sites",
        },
        {
          title: "place",
          icon: "home_work",
          store: "Place",
        },
        {
          title: "equipment",
          icon: "construction",
          store: "Equipment",
        },
        {
          title: "pop",
          icon: "memory",
          store: "Pop",
          withoutList: true,
          href: "/pops",
        },
      ],
    };
  },
};
</script>

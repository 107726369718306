<template>
  <div>
    <v-app-bar color="flow-color" dark fixed height="60">
      <router-link to="/">
        <span class="title white--text ml-3 mr-5 text-h4 font-weight-bold">
          Machine<span class="font-weight-light">Flow</span>
        </span>
      </router-link>
      <NavbarSearch
        v-if="getShowSearch"
        :class="$vuetify.breakpoint.smAndUp ? 'd-flex' : 'd-none'"
      />
      <v-spacer></v-spacer>
      <v-btn
        v-show="$vuetify.breakpoint.smAndDown"
        color="white"
        dark
        icon
        @click.stop="drawer = !drawer"
      >
        <v-icon> menu </v-icon>
      </v-btn>
      <NavbarNavigation v-if="$vuetify.breakpoint.mdAndUp" class="d-flex" />
      <v-menu v-model="account" :close-on-content-click="false" offset-y="">
        <template v-slot:activator="{ on }">
          <v-btn
            :class="$vuetify.breakpoint.mdAndUp ? 'd-flex' : 'd-none'"
            class="white--text"
            depressed
            small
            color="transparent"
            :min-width="$vuetify.breakpoint.xs ? '24' : ''"
            :width="$vuetify.breakpoint.xs ? '24' : ''"
            v-on="on"
          >
            <v-icon :class="$vuetify.breakpoint.xs ? '' : 'mr-2'"
              >account_box</v-icon
            >
            <span
              class="d-none d-sm-flex text-capitalize font-weight-regular"
              >{{ getName }}</span
            >
            <v-icon right class="ml-2 d-none d-sm-flex"
              >keyboard_arrow_down</v-icon
            >
          </v-btn>
        </template>

        <v-card width="225">
          <v-card-text class="text-center">
            <span class="text-center">{{ $t("logged_in_as") }}</span>
            <span class="d-block font-weight-bold subtitle-1 text-truncate">{{
              getName
            }}</span>
            <span class="d-block font-weight-light caption text-truncate">{{
              getEmail
            }}</span>
            <v-btn
              class="my-2"
              small
              block
              text
              color="flow-color"
              @click="goToAccountSettings"
              >{{ $t("default.edit") }}</v-btn
            >
            <v-divider
              v-if="hasPermissionToAdministrate"
              class="mb-2"
            ></v-divider>
            <v-btn
              v-if="hasPermissionToAdministrate"
              class="my-2"
              small
              block
              text
              @click="goToSettings"
            >
              {{ $t("admin") }}
            </v-btn>
            <v-divider class="mt-2"></v-divider>
            <v-btn
              class="mt-4"
              small
              block
              dark
              color="flow-color"
              @click="logout"
            >
              <v-icon small>logout</v-icon>
              {{ $t("logout") }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-main class="pt-15">
      <ChildView></ChildView>
    </v-main>
    <v-footer padless color="grey lighten-3">
      <v-col class="text-center text-footer" cols="12">
        {{ new Date().getFullYear() }} — &copy; Solvian - MachineFlow
      </v-col>
    </v-footer>
    <registration-dialog></registration-dialog>
    <MobileNavigation v-if="!$vuetify.breakpoint.mdAndUp" v-model="drawer" />
  </div>
</template>
<script>
import ChildView from "./ChildView.vue";
import RegistrationDialog from "@/components/RegistrationDialog.vue";
import NavbarNavigation from "@/components/NavbarNavigation.vue";
import NavbarSearch from "@/components/NavbarSearch.vue";
import MobileNavigation from "@/components/MobileNavigation.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    ChildView,
    RegistrationDialog,
    NavbarNavigation,
    NavbarSearch,
    MobileNavigation,
  },
  data() {
    return {
      drawer: false,
      account: false,
      hasPermissionToAdministrate: false,
    };
  },
  computed: {
    ...mapGetters("Account", ["getName", "getEmail"]),
    ...mapGetters("Account", { role: "getRole" }),
    ...mapGetters("Filter", ["getShowSearch"]),
  },
  watch: {
    role: {
      immediate: true,
      handler() {
        this.hasPermissionToAdministrate =
          this.role &&
          ["admin", "solvianist", "operational"].includes(this.role)
            ? true
            : false;
      },
    },
  },
  created() {
    this.getUserLogged();
    this.hasPermissionToAdministrate =
      this.role && ["admin", "solvianist", "operational"].includes(this.role)
        ? true
        : false;
  },
  methods: {
    ...mapActions("Account", ["getUserLogged", "logout"]),
    goToAccountSettings() {
      if (this.$route.path.match("/account") == null) {
        this.account = false;
        this.$router.push("/account");
      }
    },
    goToSettings() {
      if (this.$route.path.match("/settings") == null) {
        this.account = false;
        this.$router.push("/settings");
      }
    },
  },
};
</script>
<style scoped lang="scss">
.v-footer {
  z-index: 4;
  .text-footer {
    color: #929292 !important;
    font-weight: 300 !important;
    font-size: 14px !important;
  }
}
</style>

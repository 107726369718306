<template>
  <v-navigation-drawer
    v-model="drawer"
    width="300"
    class="registration-dialog"
    floating
    temporary
    right
    app
  >
    <template v-slot:prepend>
      <v-list-item class="px-0">
        <v-list-item-content class="pa-0">
          <v-card rounded="0" color="flow-color" dark elevation="0">
            <v-card-text class="text-center pb-0 px-0 white--text">
              <span class="text-center">{{ $t("logged_in_as") }}</span>
              <span class="d-block font-weight-bold subtitle-1 text-truncate">{{
                getName
              }}</span>
              <span class="d-block font-weight-light caption text-truncate">{{
                getEmail
              }}</span>
              <v-btn
                class="my-2"
                small
                block
                text
                color="white"
                @click="goToAccountSettings"
                >{{ $t("default.edit") }}</v-btn
              >
            </v-card-text>
          </v-card>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-container v-if="$vuetify.breakpoint.xs && getShowSearch" class="pa-0">
      <NavbarSearch
        v-if="getShowSearch"
        :class="$vuetify.breakpoint.xs ? 'd-flex' : 'd-none'"
      />
      <v-divider></v-divider>
    </v-container>
    <MenuList
      v-for="option in options"
      :key="option.title"
      :item="option"
    ></MenuList>
    <Notification />
    <template v-slot:append>
      <v-divider v-if="hasPermissionToAdministrate" class="mb-2"></v-divider>
      <v-btn
        v-if="hasPermissionToAdministrate"
        class="my-2"
        small
        block
        text
        @click="goToSettings"
      >
        {{ $t("admin") }}
      </v-btn>
      <v-divider></v-divider>
      <v-btn
        depressed
        class="mt-0 py-6 rounded-0"
        small
        block
        dark
        color="flow-color"
        @click="logout"
      >
        <v-icon small>logout</v-icon>
        {{ $t("logout") }}
      </v-btn>
    </template>
  </v-navigation-drawer>
</template>
<script>
import MenuList from "@/components/MenuList.vue";
import Notification from "@/components/Notification.vue";
import NavbarSearch from "@/components/NavbarSearch.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { MenuList, Notification, NavbarSearch },
  props: {
    value: { type: Boolean, required: true },
  },
  data() {
    return {
      options: [
        {
          title: "company",
          icon: "work",
          store: "Company",
        },
        {
          title: "site",
          icon: "business",
          store: "Site",
          viewAll: "/sites",
        },
        {
          title: "place",
          icon: "home_work",
          store: "Place",
        },
        {
          title: "equipment",
          icon: "construction",
          store: "Equipment",
        },
        {
          title: "pop",
          icon: "memory",
          store: "Pop",
          withoutList: true,
          href: "/pops",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("Account", ["getName", "getEmail"]),
    ...mapGetters("Account", { role: "getRole" }),
    ...mapGetters("Filter", ["getShowSearch"]),
    drawer: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    role: {
      immediate: true,
      handler() {
        this.hasPermissionToAdministrate =
          this.role &&
          ["admin", "solvianist", "operational"].includes(this.role)
            ? true
            : false;
      },
    },
  },
  created() {
    this.getUserLogged();
    this.hasPermissionToAdministrate =
      this.role && ["admin", "solvianist", "operational"].includes(this.role)
        ? true
        : false;
  },
  methods: {
    ...mapActions("Account", ["getUserLogged", "logout"]),
    goToAccountSettings() {
      if (this.$route.path.match("/account") == null) {
        this.account = false;
        this.$router.push("/account");
      }
    },
    goToSettings() {
      if (this.$route.path.match("/settings") == null) {
        this.account = false;
        this.$router.push("/settings");
      }
    },
  },
};
</script>

<template>
  <div class="text-center">
    <v-navigation-drawer
      v-model="dialog"
      class="registration-dialog"
      floating
      temporary
      left
      app
      width="880"
    >
      <v-container class="pa-0">
        <v-row no-gutters>
          <v-col cols="12">
            <div class="title flow-color pa-4 d-flex">
              <label class="text-h5 white--text flex-grow-1 text-truncate">
                {{ getTitle() }}
              </label>
              <div class="btn-close ml-auto">
                <v-btn icon color="white" @click="closeDialog()"
                  ><v-icon class="material-icons">close</v-icon></v-btn
                >
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-0">
          <v-col
            v-if="!$vuetify.breakpoint.xs"
            cols="12"
            sm="4"
            class="grey lighten-4 custom-navigation-drawer pa-0"
          >
            <v-card class="mx-auto" flat color="grey lighten-4">
              <v-card-title class="ma-3 group-title">
                <v-icon class="material-icons" large left> article </v-icon>
                <span class="text-h6 font-weight-light">{{
                  $t("register")
                }}</span>
              </v-card-title>
            </v-card>
            <v-divider></v-divider>
            <v-list
              class="pa-0 pt-4 custom-list"
              color="grey lighten-4"
              dense
              nav
              flat
            >
              <v-list-item
                v-for="item in sideListRegistration"
                :key="item.title"
                v-ripple="{ class: `white--text` }"
                color="indigo darken-4"
                class="px-8 py-3 rounded-0"
                :class="item.store === getStore() ? 'highlighted' : ''"
                @click="changeForm(item.store)"
              >
                <v-list-item-icon class="mr-4">
                  <v-icon class="material-icons" v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-left">{{
                    $tc(`${item.title}.label`, 1)
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12" sm="8">
            <component
              :is="currentForm"
              v-if="currentForm !== null"
              :item="getItem()"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      currentForm: null,
      sideListRegistration: [
        {
          title: "company_group",
          icon: "apartment",
          store: "CompanyGroup",
        },
        {
          title: "company",
          icon: "work",
          store: "Company",
        },
        {
          title: "site",
          icon: "business",
          store: "Site",
        },
        {
          title: "place",
          icon: "home_work",
          store: "Place",
        },
        {
          title: "equipment",
          icon: "construction",
          store: "Equipment",
        },
        {
          title: "modem",
          icon: "sim_card",
          store: "Modem",
        },
        {
          title: "sensor",
          icon: "settings_input_antenna",
          store: "Sensor",
        },
        {
          title: "pop",
          icon: "memory",
          store: "Pop",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("RegistrationDialog", {
      dialogFromStore: "getDialog",
      storeChanged: "getStore",
    }),
    dialog: {
      get() {
        return this.dialogFromStore;
      },
      set(newValue) {
        this.updateDialog(newValue);
        return newValue;
      },
    },
  },
  watch: {
    storeChanged(val) {
      if (val) {
        this.currentForm = () => import(`@/components/forms/${val}Form.vue`);
      }
    },
  },
  methods: {
    ...mapActions("RegistrationDialog", [
      "closeDialog",
      "updateDialog",
      "changeForm",
      "save",
    ]),
    ...mapGetters("RegistrationDialog", [
      "getTitle",
      "getStore",
      "getItem",
      "getResetForm",
    ]),
  },
};
</script>
<style lang="scss" scoped>
.registration-dialog {
  .title {
    height: 64px;
  }
  .group-title i,
  .group-title span {
    color: #404143 !important;
  }
  .v-list {
    max-height: calc(100vh - 157px);
    overflow: hidden;
    overflow-y: auto;
    .highlighted {
      background: $violet-primary;
      .v-list-item__title,
      .v-list-item__icon i {
        color: white !important;
      }
    }
    .v-list-item__title {
      color: #404143 !important;
      font-size: 18px !important;
    }
    .v-list-item__icon i {
      color: #404143 !important;
    }
  }
}
</style>

<template>
  <div class="menu" :class="$vuetify.breakpoint.mdAndUp ? 'pl-1' : ''">
    <!-- Mobile -->
    <v-list-item
      v-show="$vuetify.breakpoint.smAndDown"
      v-ripple
      @click="goToEvents()"
    >
      <v-list-item-avatar>
        <v-icon color="indigo">notifications </v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title> {{ $tc(`event.label`, 2) }}</v-list-item-title>
      </v-list-item-content>

      <v-list-item-action class="action-list-item text-center">
        <v-list-item-action-text>Total</v-list-item-action-text>
        <span class="w-100">{{ getMenuLoading ? "..." : getMenuCount }}</span>
      </v-list-item-action>
    </v-list-item>

    <!--DESKTOP-->
    <v-menu
      v-model="menu"
      left
      offset-y
      transition="scroll-y-transition"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <div
          v-show="$vuetify.breakpoint.mdAndUp"
          v-ripple
          class="navbar-item pa-2 rounded-pill"
          v-on="on"
        >
          <v-tooltip bottom open-delay="500">
            <template v-slot:activator="{ on, attrs }">
              <div class="item" v-bind="attrs" v-on="on">
                <v-badge
                  :value="!getMenuLoading && getMenuCount == 0 ? false : true"
                  class="menu-badge"
                  color="red darken-1"
                  :content="getMenuLoading ? '...' : getMenuCount"
                  bottom
                  overlap
                >
                  <v-icon color="white" class="material-icons" depressed>
                    notifications
                  </v-icon>
                </v-badge>
              </div>
            </template>
            <span>{{ $tc(`event.label`, 2) }}</span>
          </v-tooltip>
        </div>
      </template>
      <v-list class="pt-0" max-width="400" min-width="400">
        <v-list-item-title class="list-title px-4 py-2 d-flex align-center">
          <span class="font-weight-bold">{{ $tc(`event.label`, 2) }}</span>
          <v-spacer></v-spacer>
          <small>
            <a @click="goToEvents()">Ver todos</a>
          </small>
        </v-list-item-title>
        <div id="loadMore" ref="loadMore" class="list-item-group">
          <v-list-item
            v-for="l in getMenuList"
            :key="l.id"
            class="list-item pa-0"
          >
            <v-list-item-content
              v-ripple
              :class="l.notify_dashboard ? 'notify-dashboard' : ''"
              class="px-4"
              @click.stop="goTo(l.pop_id, l.subscription_started_at)"
            >
              <v-list-item-title
                class="notification-title d-flex justify-space-between"
              >
                <span class="text-dark text-truncate notify-message"
                  >{{ l.pop.name }} {{ l.message }}</span
                >
                <v-tooltip top open-delay="500">
                  <template v-slot:activator="{ on }">
                    <small class="text-muted" v-on="on">{{
                      l.subscription_started_at | fromNow
                    }}</small>
                  </template>
                  <span>{{ l.subscription_started_at | formatDateTime }}</span>
                </v-tooltip>
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-chip
                  class="rounded mr-2 white--text px-2"
                  :class="`severity-${l.severity}`"
                  x-small
                  >{{ l.event_type.name_text }}</v-chip
                >
                <v-chip class="rounded px-2 pop-label" x-small label>{{
                  l.pop.name
                }}</v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="!getMenuLoading && getMenuList.length == 0">
            <v-list-item-avatar>
              <i class="material-icons">do_not_disturb</i>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="search-not-found"
                >Não há nenhuma notificação.</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <div v-if="getMenuLoading" class="list-item-group">
            <v-list-item v-for="index in 5" :key="index" class="d-block">
              <v-sheet color="white" class="pa-2 pb-0 px-0">
                <v-skeleton-loader
                  class="mx-auto loading-card-menu"
                  type="text"
                ></v-skeleton-loader>
              </v-sheet>
            </v-list-item>
          </div>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      menu: false,
      page: 1,
    };
  },
  computed: {
    ...mapGetters("Account", { lowestSeverity: "getLowestSeverityInterest" }),
    ...mapGetters("Event", ["getMenuCount", "getMenuList", "getMenuLoading"]),
    ...mapGetters("Filter", {
      company: "getCompany",
      site: "getSite",
      place: "getPlace",
      equipment: "getEquipment",
    }),
  },
  watch: {
    lowestSeverity(n, o) {
      if (n != o && !this.$_.isEqual(o, n) && !this.getMenuLoading) {
        this.page = 1;
        this.resetMenuList();
        this.getListMenu(this.page);
      }
    },
    company(n, o) {
      if (!this.$_.isEqual(o, n) && !this.getMenuLoading) {
        this.page = 1;
        this.resetMenuList();
        this.getListMenu(this.page);
      }
    },
    site(n, o) {
      if (!this.$_.isEqual(o, n) && !this.getMenuLoading) {
        this.page = 1;
        this.resetMenuList();
        this.getListMenu(this.page);
      }
    },
    place(n, o) {
      if (!this.$_.isEqual(o, n) && !this.getMenuLoading) {
        this.page = 1;
        this.resetMenuList();
        this.getListMenu(this.page);
      }
    },
    equipment(n, o) {
      if (!this.$_.isEqual(o, n) && !this.getMenuLoading) {
        this.page = 1;
        this.resetMenuList();
        this.getListMenu(this.page);
      }
    },
  },
  created() {
    this.resetMenuList();
    this.getListMenu(this.page);
    window.addEventListener("scroll", this.onScroll);
  },
  updated() {
    if (this.$refs.loadMore) {
      this.loadMore();
    }
  },
  methods: {
    ...mapActions("Event", ["getListMenu", "resetMenuList"]),
    goToEvents() {
      this.menu = false;
      this.$router.push("/events");
    },
    goTo(id, started_at) {
      let date = this.moment(started_at),
        start = date.startOf("day").utc().format(),
        end = date.endOf("day").utc().format();

      this.$router.push({
        path: `/pop/${id}`,
        query: { q: `${start} ${end}` },
      });
    },
    loadMore() {
      var self = this;
      document.getElementById("loadMore").onscroll = function () {
        let elem = document.querySelector("#loadMore");
        if (elem) {
          if (elem.scrollTop >= elem.scrollHeight - elem.offsetHeight) {
            elem.scrollTop = elem.scrollHeight;
            if (
              !self.getMenuLoading &&
              self.getMenuList.length != self.getMenuCount
            ) {
              self.page += 1;
              self.getListMenu(self.page);
            }
          }
        }
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.navbar-item {
  cursor: pointer;
}
.list-title {
  font-size: 0.75em !important;
  color: #6c757d;
  border-bottom: 1px solid #dee2e6 !important;
  background: #f8f9fa;
}
.list-item {
  border-bottom: 1px solid #dee2e6 !important;
}
.list-item-group {
  max-height: calc(50vh);
  overflow-y: auto;
}
.selected-label {
  max-width: 100px !important;
  display: inline-block;
  vertical-align: middle;
  letter-spacing: 0.0892857143em;
  font-size: 0.75em;
}

.notification-title {
  font-size: 0.8em !important;
}

.notify-message {
  max-width: 300px;
}

.pop-label {
  color: #444444 !important;
  font-weight: 500;
}

.action-list-item {
  height: 40px;
  margin-top: 16px;
}

.w-100 {
  width: 100%;
}
</style>

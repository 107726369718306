<template>
  <div
    class="menu"
    :class="
      $vuetify.breakpoint.mdAndUp
        ? selected && selected.name
          ? 'pr-0'
          : 'pr-1'
        : ''
    "
  >
    <v-menu
      v-model="menu"
      eager
      left
      offset-y
      transition="scroll-y-transition"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <!-- Mobile -->
        <v-list-item
          v-if="$vuetify.breakpoint.smAndDown"
          v-ripple
          two-line
          v-on="on"
          @click="item.withoutList ? goTo(item.href) : ''"
        >
          <v-list-item-avatar>
            <v-icon color="indigo">{{ item.icon }} </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              {{
                $tc(`${item.store.toLowerCase()}.label`, 1)
              }}</v-list-item-title
            >
            <v-list-item-subtitle
              v-if="!item.withoutList"
              class="text-truncate width-subtitle"
              >{{ selected.name }}</v-list-item-subtitle
            >
          </v-list-item-content>

          <v-list-item-action class="action-list-item text-center">
            <v-list-item-action-text>Total</v-list-item-action-text>
            <span class="w-100">{{ loading ? "..." : totalCount }}</span>
          </v-list-item-action>
        </v-list-item>

        <!-- DESKTOP -->
        <div
          v-else
          v-ripple
          class="navbar-item pa-2 rounded-pill"
          v-on="on"
          @click="item.withoutList ? goTo(item.href) : ''"
        >
          <v-tooltip bottom open-delay="500">
            <template v-slot:activator="{ on, attrs }">
              <div class="item d-flex align-center" v-bind="attrs" v-on="on">
                <v-badge
                  :value="totalCount == 0 ? false : true"
                  class="menu-badge"
                  color="blue-grey darken-1"
                  :content="loading ? '...' : totalCount"
                  bottom
                  overlap
                >
                  <v-icon color="white" class="material-icons" depressed>
                    {{ item.icon }}
                  </v-icon>
                </v-badge>
                <span
                  v-if="!item.withoutList"
                  :class="selected.name ? 'ml-4' : 'mx-0'"
                  class="selected-label text-truncate"
                  >{{ selected.name }}</span
                >
              </div>
            </template>
            <span
              >{{ $tc(`${item.store.toLowerCase()}.label`, 1) }}
              {{
                !item.withoutList && selected.name ? `- ${selected.name}` : ""
              }}</span
            >
          </v-tooltip>
        </div>
      </template>
      <v-list
        v-if="!item.withoutList"
        class="pt-0"
        min-width="200"
        max-width="400"
      >
        <v-list-item-title class="list-title px-4 py-2 d-flex align-center">
          <span class="font-weight-bold">{{
            $tc(`${item.title}.label`, 2)
          }}</span>
          <v-spacer></v-spacer>
          <small v-if="item.viewAll">
            <a @click="goTo(item.viewAll)">Ver todos</a>
          </small>
        </v-list-item-title>
        <v-list-item class="list-item">
          <v-text-field
            :ref="`search_${item.store}`"
            v-model="searchModel"
            class="my-3"
            prepend-inner-icon="search"
            hide-details=""
            outlined
            dense
            autocomplete="off"
            aria-autocomplete="off"
            autofocus
            :loading="searchLoading"
            @keyup="search()"
          ></v-text-field>
        </v-list-item>
        <div v-if="!loading" class="list-item-group">
          <v-list-item v-for="l in list" :key="l.id" class="list-item">
            <v-list-item-content>
              <v-list-item-title>{{ l.name }}</v-list-item-title>
              <v-list-item-subtitle
                v-if="item.title == 'company' && l.bis && l.bis.length > 0"
              >
                <v-icon color="flow-color" small class="material-icons mr-1"
                  >mdi-monitor-dashboard</v-icon
                >
                <a
                  v-for="bi in l.bis"
                  :key="bi.id"
                  class="mr-1"
                  target="_blank"
                  :href="bi.url"
                  ><small>[ {{ bi.name }} ] </small></a
                >
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="selectItem(l)">
                <v-icon
                  :color="l.id === selected.id ? 'flow-color' : 'black'"
                  class="material-icons"
                  >{{
                    l.id === selected.id
                      ? "radio_button_on"
                      : "radio_button_off"
                  }}</v-icon
                >
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="list.length == 0">
            <v-list-item-avatar>
              <i class="material-icons">search_off</i>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="search-not-found"
                >Ops. Não encontramos o que você esta
                procurando.</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </div>
        <div v-if="loading" class="list-item-group">
          <v-list-item v-for="index in 5" :key="index" class="d-block">
            <v-sheet color="white" class="pa-2 pb-0 px-0">
              <v-skeleton-loader
                class="mx-auto loading-card-menu"
                type="text"
              ></v-skeleton-loader>
            </v-sheet>
          </v-list-item>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      menu: false,
      searchModel: null,
      searchLoading: false,
    };
  },
  computed: {
    loading: {
      get() {
        return this.$store.getters[`${this.item.store}/getMenuLoading`];
      },
    },
    totalCount: {
      get() {
        return this.$store.getters[`${this.item.store}/getMenuCount`];
      },
    },
    list: {
      get() {
        return this.$store.getters[`${this.item.store}/getMenuList`];
      },
    },
    selected: {
      get() {
        return this.$store.getters[`Filter/get${this.item.store}`];
      },
      set(val) {
        this.$store.dispatch(`Filter/set${this.item.store}`, val);
      },
    },
  },
  created() {
    if (!this.totalCount) {
      this.$store.dispatch(`${this.item.store}/getListMenu`);
    }
  },
  methods: {
    goTo(url) {
      this.menu = false;
      this.$router.push(url);
    },
    search() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.searchLoading = true;
        this.$store
          .dispatch(`${this.item.store}/getListMenu`, this.searchModel)
          .then(() => {
            this.searchLoading = false;
          });
      }, 500);
    },
    selectItem(item) {
      if (item == this.selected) {
        this.selected = {};
      } else {
        this.selected = item;
      }
      this.menu = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.navbar-item {
  cursor: pointer;
}
.list-title {
  font-size: 0.75em !important;
  color: #6c757d;
  border-bottom: 1px solid #dee2e6 !important;
  background: #f8f9fa;
}
.list-item {
  border-bottom: 1px solid #dee2e6 !important;
}
.list-item-group {
  max-height: calc(60vh);
  overflow-y: auto;
}
.selected-label {
  max-width: 100px !important;
  display: inline-block;
  vertical-align: middle;
  letter-spacing: 0.0892857143em;
  font-size: 0.75em;
}
.action-list-item {
  height: 40px;
  margin-top: 16px;
}
.width-subtitle {
  max-width: 168px;
}
.w-100 {
  width: 100%;
}
</style>

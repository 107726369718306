<template>
  <div class="search-navbar">
    <v-menu
      v-if="!rootFilterView"
      v-model="showFilter"
      content-class="search-menu elevation-1"
      left
      offset-y
      transition="scroll-y-transition"
      :close-on-content-click="false"
      max-width="500"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="name"
          class="my-3 search-input search-width"
          :class="showFilter ? 'search-focused' : ''"
          prepend-inner-icon="search"
          hide-details=""
          outlined
          dense
          autocomplete="off"
          aria-autocomplete="off"
          :placeholder="`Pesquisar pelo ${search_placeholder}`"
          @click:append="showFilter = !showFilter"
        >
          <template v-slot:append>
            <v-btn
              height="24"
              min-width="20"
              class="px-1"
              depressed
              color="transparent"
              v-on="on"
              ><v-icon class="material-icons">tune</v-icon></v-btn
            >
          </template>
        </v-text-field>
      </template>
      <v-list class="pt-0 search-menu search-width pa-3 pb-0">
        <component :is="currentFilter" v-if="currentFilter !== null" />
      </v-list>
    </v-menu>
    <component
      :is="currentFilter"
      v-else-if="currentFilter !== null && rootFilterView"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      currentFilter: null,
      showFilter: false,
      timer: null,
      search_placeholder: "nome",
      firstTime: 0,
    };
  },
  computed: {
    ...mapGetters("Filter", ["getPathComponent", "getFilteredFields"]),
    ...mapState("Filter", ["rootFilterView"]),
    name: {
      set(val) {
        if (this.firstTime > 1) {
          if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
          }
          this.timer = setTimeout(() => {
            if (
              this.getPathComponent != "filters/Sensor" &&
              this.getPathComponent != "filters/Modem" &&
              this.getPathComponent != "filters/EventType"
            ) {
              this.setFieldOnFilterdFields({ name_cont: val });
            } else if (this.getPathComponent == "filters/Sensor") {
              this.setFieldOnFilterdFields({ serial_cont: val });
            } else if (this.getPathComponent == "filters/Modem") {
              this.setFieldOnFilterdFields({ mac_cont: val });
            } else if (this.getPathComponent == "filters/EventType") {
              this.setFieldOnFilterdFields({ name_text_cont: val });
            }
          }, 1000);
        }
        this.firstTime += 1;
      },
      get() {
        return this.getFilteredFields?.name_cont;
      },
    },
  },
  watch: {
    getPathComponent(n, o) {
      if (!this.$_.isEqual(o, n)) {
        if (this.getPathComponent == "filters/Sensor") {
          this.search_placeholder = "serial do sensor";
        } else if (this.getPathComponent == "filters/Modem") {
          this.search_placeholder = "MAC";
        } else {
          this.search_placeholder = "nome";
        }
        this.currentFilter = () =>
          import(`@/components/${this.getPathComponent}.vue`);

        if (!["filters/PopDetailsFilter", "filters/Pop"].includes(n)) {
          this.resetFilter();
        }
      }
    },
  },
  created() {
    if (this.getPathComponent) {
      if (this.getPathComponent == "filters/Sensor") {
        this.search_placeholder = "serial do sensor";
      } else if (this.getPathComponent == "filters/Modem") {
        this.search_placeholder = "MAC";
      } else {
        this.search_placeholder = "nome";
      }
      this.currentFilter = () =>
        import(`@/components/${this.getPathComponent}.vue`);

      if (
        !["filters/PopDetailsFilter", "filters/Pop"].includes(
          this.getPathComponent
        )
      ) {
        this.resetFilter();
      }
      this.name = "";
    }
  },
  methods: {
    ...mapActions("Filter", ["setFieldOnFilterdFields", "resetFilter"]),
  },
};
</script>
